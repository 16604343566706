<template>
    <v-container fill-height class="align-start black" fluid>
    <v-row class="" justify="center">
        <menus/>
        <v-col cols="12" md="11"  class="fondo justify-center">
            
            <v-responsive min-height="1000" class="text-center mx-auto mt-10 mt-md-1">
                <h1 class="mb-5 mt-5">¡DE ALEMANIA PARA EL MUNDO!</h1>
                <div class="mb-5" style="font-size: 100%">
                    BECK’S, ES UNA LAGER CON UN SABOR Y UNA AMARGURA &Uacute;NICOS.
                </div>
                <v-row class=" textoR">
                    <v-col cols="4" md="4" class="mr-md-n15 pr-md-n15 text-right textoR" >
                        <h2 class="background--text ">  LA ÚNICA LAGER CON CERTIFICADO DE PUREZA ALEMANA</h2>
                        <div class="mb-md-15">
                            CEBADA + LÚPULO + AGUA +LEVADURA = NO NECESITAMOS NADA 
                            MÁS PARA CREAR UNA BECK’S. ASÍ DE SIMPLE: NO CAMBIAMOS NUESTRA 
                            FÓRMULA DESDE EL SIGLO XIX.
                        </div>
                        <h2 class="background--text mt-md-15"> COMO NINGUNA OTRA</h2>
                        <div> 
                            NO SÓLO ES DORADA Y BRILLANTE: LA EXPERIENCIA EN EL PALADAR 
                            ES ÚNICA. COMIENZA LIGERAMENTE FRUTAL, CON NOTAS A MANZANA VERDE Y 
                            CÍTRICOS, Y SE TRANSFORMA EN ALGO MÁS HERBAL/FLORAL.
                        </div>
                    </v-col>
                    <v-col cols="4" md="4" class="d-flex">
                        &nbsp;
                        <v-img
                            class="ml-n9 mt-n5 mb-5 d-md-none"
                            contain
                            width="100%"
                            min-width="180px"
                            :src="require('../assets/lata.png')"
                        />
                        <v-img
                            class="mt-n4 mb-5 ml-10 d-none d-md-inline"
                            style="z-index:4;"
                            width="85%"
                            :src="require('../assets/lata.png')"
                        />
                    </v-col>
                    <v-col cols="4" md="4" class="justify-start ml-md-n15 text-left" style="z-index:2">
                        <h2 class="background--text">TODO UN VIAJE</h2>
                        <div class="mb-md-15">
                            ¿QUÉ HACE A BECK’S DIFERENTE DE OTRAS LAGERS? COMIENZA 
                            MUY SUAVE Y UN POQUITO ÁCIDA, PARA IR DESCUBRIENDO POCO A POCO SU FINAL 
                            SECO, REFRESCANTE Y DELICIOSAMENTE AMARGO.
                        </div>
                        <div>
                            UNA CERVEZA ASÍ ES LA COMPAÑERA IDEAL PARA SABORES INTENSOS: UN PAD 
                            THAI, UN AGUACHILE O UNOS NOODLES CON CHILI OIL VAN PERFECTO CON BECK’S.
                        </div>
                        <h2 class="background--text mt-md-15">BÁSICOS</h2>
                        <div>
                            CUERPO MEDIO, REFRESCANTE, IBU 21 Y 5° DE ALCOHOL.
                        </div>
                    </v-col>
                    <v-col>
                            <h1>COMPRAR CON UN CLIC</h1>
                        <v-row no-gutters class="d-flex ">
                            <v-col cols="6" md="6" class="d-flex align-md-end align-center justify-center" >
                                <a
                                    href="https://www.beerhouse.mx/landing/cerveza-becks-2022"
                                    target="_blank"
                                >
                                    <v-img
                                        width="30%"
                                        class="rounded-xl mb-2 d-none d-md-flex mx-auto"
                                        src= '../assets/infoBecksBeerHouse.png'
                                    ></v-img>
                                    <v-img
                                        width="50vw"
                                        class="rounded-xl mb-2 d-md-none mx-auto"
                                        src= '../assets/infoBecksBeerHouse.png'
                                    ></v-img>
                                </a>
                            </v-col>
                            <v-col cols="6" md="6" class="d-flex justify-md-start align-center justify-center">
                                <a
                                    href="https://now.modelorama.com.mx/becks?_q=becks&map=ft"
                                    target="_blank"
                                >
                                    <v-img
                                        width="45%"
                                        class="rounded-xl mb-5 d-none d-md-flex mx-auto "
                                        src= '../assets/infoBecksModelorama.png'
                                    ></v-img>
                                    <v-img
                                        width="45vw"
                                        class="rounded-xl mb-5 d-md-none mx-auto"
                                        src= '../assets/infoBecksModelorama.png'
                                    ></v-img>
                                </a>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-responsive class="align-center botones aling-self-md-end text-center mb-16 pb-8 d-md-none" style="z-index: 3" >

                        <v-img
                            width="55%"
                            class="mx-auto blanco rounded-xl"
                            src= '../assets/infoBecksAbajo.png'
                        ></v-img>
                        <a
                            href="https://www.facebook.com/becksmexico"
                            style="text-decoration: none"
                            target="_blank"
                        >
                            <v-btn icon  x-large class="ml-n2 ml-md-5 black" color="white">
                                <v-icon x-large >mdi-facebook</v-icon>
                            </v-btn>
                        </a>
                        <a
                        href="https://www.instagram.com/becksmexico/"
                        style="text-decoration: none"
                        target="_blank"
                        >
                        <v-btn icon color="white" x-large class="ml-n2 ml-md-5 black" >
                            <v-icon x-large>mdi-instagram</v-icon>
                        </v-btn>
                        </a>
                        <a
                        href="https://www.youtube.com/channel/UCSD0VXsNGfldVvU_dQQgMJQ"
                        style="text-decoration: none"
                        target="_blank"
                        >
                        <v-btn icon color="white" x-large class="ml-n2 ml-md-5 black">
                            <v-icon x-large>mdi-youtube</v-icon>
                        </v-btn>
                        </a>
                        <a
                            href="https://twitter.com/Becks_Mx"
                            style="text-decoration: none"
                            target="_blank"
                        >
                        <v-btn icon color="white" x-large class="ml-n2 ml-md-5 black">
                            <v-icon x-large>mdi-twitter</v-icon>
                        </v-btn>
                        </a>
                    </v-responsive>
                </v-row>
            </v-responsive>
            <v-responsive class="align-center botones aling-self-md-end text-center mb-16 pb-8 d-none d-md-flex">
                <v-img
                        
                        width="40%"
                        class="mx-auto"
                        src= '../assets/infoBecksAbajo.png'
                    ></v-img>
                <a
                    href="https://www.facebook.com/becksmexico"
                    style="text-decoration: none"
                    target="_blank"
                >
                    <v-btn icon color="black" x-large class="mx-2 ml-md-5">
                        <v-icon size="70">mdi-facebook</v-icon>
                    </v-btn>
                </a>
                <a
                  href="https://www.instagram.com/becksmexico/"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <v-btn icon color="black" x-large class="mx-2 ml-md-5">
                    <v-icon size="70">mdi-instagram</v-icon>
                  </v-btn>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCSD0VXsNGfldVvU_dQQgMJQ"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <v-btn icon color="black" x-large class="mx-2 ml-md-5">
                    <v-icon size="70">mdi-youtube</v-icon>
                  </v-btn>
                </a>
                <a
                    href="https://twitter.com/Becks_Mx"
                    style="text-decoration: none"
                    target="_blank"
                >
                  <v-btn icon color="black" x-large class="mx-2 ml-md-5">
                    <v-icon size="70">mdi-twitter</v-icon>
                  </v-btn>
                </a>
            </v-responsive>
        </v-col>

    </v-row>
    </v-container>
</template>

<script>
    import menus from '../components/menus.vue'

    export default {
        data: () => ({
        }),
        methods: {
            
        },
        computed:{

        },
        name: 'Infobeacks',
        components: {
            menus
        },
    }
</script>

<style scoped>
    .blanco{
        background-color:  rgba(255,255,255,.3);
    }
    .rotar{
        transform: rotate(-25deg);
    }
    .rotar2{
        transform: rotate(-1deg);
    }
    .fondo{
        background-image: url("../assets/infoBecksWeb.png");
        background-size: cover;
        background-position-x: center;
    }
    .textoR{
        font-size: 12pt;
    }
    @media (max-width: 960px){
        .fondo{
            background-image: url("../assets/FondoM16.jpg");
            background-size: cover;
            
        } 
        .textoR{
            font-size: 6pt;
        } 
    }
</style>